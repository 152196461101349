// script.js

// Web3Modal
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers';
import { ethers, BrowserProvider, Contract, parseUnits } from 'ethers';

// Dynamically load the Wallet Connection State module from the path specified
// Remember, dynamic imports are promises, so they are asynchronous. We'll need to handle them using async/await or .then()
const walletConnectionState = async () => {
    try {
        const modulePath = '/wp-content/DApp-WalletConnectionState/walletConnectionState.js';
        const loginStateModule = await import(/* webpackIgnore: true */ modulePath);
        return loginStateModule.default;  // return the loginState instance

    } catch (error) {
        console.error('Failed to load walletConnectionState module:', error);
        return null;
    }
};

// Dynamically load the loginState module from the path specified
// Remember, dynamic imports are promises, so they are asynchronous. We'll need to handle them using async/await or .then()
const loadLoginState = async () => {
    try {
        const modulePath = '/wp-content/DApp-loginState/loginState.js';
        const loginStateModule = await import(/* webpackIgnore: true */ modulePath);
        return loginStateModule.default;  // return the loginState instance

    } catch (error) {
        console.error('Failed to load loginState module:', error);
        return null;
    }
};

// Dynamically load the supplyBar module from the path specified
// Remember, dynamic imports are promises, so they are asynchronous. We'll need to handle them using async/await or .then()
async function loadAndFetchNftSupply() {
    try {
        const modulePath = '/wp-content/DApp-Supply-Bar/js/script.js';
        const supplyBarModule = await import(/* webpackIgnore: true */ modulePath);
        await supplyBarModule.default(); // Or call a function directly
    } catch (error) {
        console.error('Failed to load module:', error);
        return null;
    }
}

//
//
// Logic to Detect "In-App" browsers and hide the google sign in button
//
//

// Main function to initialize in-app browser detection 
function initializeBrowserDetectionLogic() {
    if (detectInAppBrowser()) {
        handleInAppBrowser();
    } 
}

// Function to detect if we are in an in-app browser
function detectInAppBrowser() {
    var ua = navigator.userAgent;

    // User Agent detection for Instagram, TikTok, and Facebook (Meta)
    if (/Instagram|FBAN|FBAV|musical_ly|TikTok/i.test(ua)) {
        return true;
    }

    // Behavioral detection specific to TikTok's in-app browser
    if (window.TikTokWebView || typeof navigator.mediaDevices === 'undefined') {
        return true;
    }

    return false;  // If none of the conditions are met, assume it's not an in-app browser
}

// Function to handle in-app browser behavior
function handleInAppBrowser() {
    // Hide the Google Sign-In elements by targeting their wrapper divs
    var googleSignInElements = document.querySelectorAll('.outer-wrapper-for-google-sign-in, .google-signin-wrapper, #registerText, #signInEmailPasswordButton, #registerText2, #emailPasswordButton');

    // Loop through all the elements that need to be hidden and set display to 'none'
    googleSignInElements.forEach(function(element) {
        element.style.display = 'none';
    });

    // Show the in-app browser detected notice for two elements
    var inAppBrowserNotices = document.querySelectorAll('#inAppBrowserdetected, #inAppBrowserdetected2, .account-creation-form');

    // Loop through both elements and set display to 'block'
    inAppBrowserNotices.forEach(function(element) {
        element.style.display = 'block';  // Make each notice visible
    });

    // Main Sign in (Username / Password)
    document.querySelector('#signInInputFields').style.display = 'flex';
}

// Call the main function when the DOM is fully loaded
document.addEventListener("DOMContentLoaded", function() {
    initializeBrowserDetectionLogic();
});

document.addEventListener("DOMContentLoaded", function() {

    //
    //
    // DOM elements - Main Landing Page
    //
    //


    const elements = {

        // Main containers
        signInContainer: document.querySelector('.signin-container-dashboard'),
        mintingInterface: document.querySelector('.minting-interface'),

        // Sign In Interface (Main Input-Form Section)
        signinInterface: document.querySelector('.signin-interface'),

        // Sign In Interface elements
        signInInputFields: document.getElementById("signInInputFields"),
        backToSignInLinkMainPage: document.getElementById("back-to-signin-main-page"),
        backToSignInLinkSecondPage: document.getElementById("back-to-signin-second-page"),

            // THE FOLLOWING ARE NOT USED:
            // signinHeader: document.querySelector('.signin-header-text'),
            // optionsDiv: document.querySelector('.options'),
            // subheaderText: document.querySelector('.subheader-text'),
            // profileIcon: document.querySelector('.profile-icon'),

        // Account Creation (Header Elements) (Wallet Options Page)
        createAccountSection: document.querySelector('.create-account-section'),
        createAccountHeader: document.querySelector('.create-account-header'),

        // GoogleAuth | New User
        newUserMoreInfoSection: document.querySelector('.new-user-more-info-section'),
        completeAccountHeader: document.querySelector('.complete-account-header'),
        logOutContainer3: document.querySelector('.logout-container-3'),

        // Account Creation Options | GoogleAuth
        accountCreationOptions: document.getElementById('accountCreationOptions'),
        googleAuthButton: document.getElementById('googleAuthButton'),
        emailPasswordButton: document.getElementById('emailPasswordButton'),
        signInEmailPasswordButton: document.getElementById('signInEmailPasswordButton'),

        // Email Verification Re-direct
        emailConfirmedMessage: document.querySelector('.verification-success-message'),

        // Register Now Link Clicks
        registerNowLink: document.querySelector('.register-now-Link'),
        registerNowLink2: document.querySelector('.register-now-link-2'),

        // Handles the display of the Account Creation Form
        accountCreationForm: document.querySelector('.account-creation-form'),

        // Handles the diaplay of the Sign-In input fields | GoogleAuth
        signInInputFields: document.getElementById('signInInputFields'),


        // ACCOUNT
        // Used for collecting form data + backend processing
        walletForm: document.getElementById("accountForm"),

        // Main connect button
        connectWalletWeb3Modal: document.getElementById("connectWalletButton"),


        // Forgot password related elements
        forgotPasswordLink: document.getElementById("forgot-password"),
        forgotPasswordModal: document.getElementById("forgotPasswordModal"),
        submitForgotPassword: document.getElementById("submitForgotPassword"),
        cancelForgotPassword: document.getElementById("cancelForgotPassword"),

        // Reset password related elements
        resetPasswordModal: document.getElementById("resetPasswordModal"),
        submitResetPassword: document.getElementById('submitResetPassword'),
        cancelResetPassword: document.getElementById('cancelResetPassword'),

        // Other UI Elements
        backToSignInText: document.querySelector('.back-to-signin-text'),

        // Minting Interface
        incrementButton: document.getElementById("incrementButton"),
        decrementButton: document.getElementById("decrementButton"),

        mintingEthAddressDiv: document.getElementById("mintingEthAddress"),

        mintNowButtonWeb3Modal: document.getElementById("mintNowButton"),

        logUserOut: document.getElementById("logoutButton2"),
        logUserOut3: document.getElementById("logoutButton3"),

        coreMintingFunctionality: document.querySelector('.core-minting-functionality'),
        connectWalletDiv: document.querySelector('.connect-wallet-div'),
        insufficientETHBalanceDiv:document.querySelector('.insufficient-eth-balance'),

        copyAddressButton: document.getElementById("copyEthAddress"),
        mercuryoLinkButton: document.getElementById("mercuryoLink"),
        scrollUpButton: document.getElementById("scrollUp"),

        downloadWalletDiv: document.querySelector('.wallet-download-div'),

        // NFT Display
        accountCTADiv: document.querySelector('.account-cta-div'),
        loadingSpinnerNFT: document.querySelector('.loading-spinner-nft'),
        wrapperDisplayDiv: document.querySelector('.nft-display-wrapper'),
        infoElement: document.getElementById('nftInfo'),

        //Loading Animation
        loadingAnimation: document.querySelector('.loading-overlay'),
    };


    //
    //
    // Event Listeners
    //
    //


    // Attach event listeners
    attachEventListeners();

    function attachEventListeners() {
        // Sign In Interface events
        elements.signInInputFields.addEventListener("submit", handleUserSignIn);
        elements.backToSignInLinkMainPage.addEventListener("click", showSignInInterface);
        elements.backToSignInLinkSecondPage.addEventListener("click", showSignInInterface);
    
        // Register Now Link Clicks
        elements.registerNowLink.addEventListener("click", handleRegisterNowClick);
        elements.registerNowLink2.addEventListener("click", handleRegisterNowClick);


        // Account creation events
        elements.walletForm.addEventListener("submit", handleAccountFormSubmit);
    

        // Forgot password events
        elements.forgotPasswordLink.addEventListener("click", toggleForgotPasswordModal);
        elements.submitForgotPassword.addEventListener("click", handleSubmitForgotPassword);
        elements.cancelForgotPassword.addEventListener("click", hideForgotPasswordModal);
    
        // Reset password events
        elements.submitResetPassword.addEventListener('click', resetUserPassword);
        elements.cancelResetPassword.addEventListener('click', hideResetPasswordModal);

        // GoogleAuth
        elements.emailPasswordButton.addEventListener('click', showEmailPasswordForm);
        elements.signInEmailPasswordButton.addEventListener('click', showSignInEmailPasswordFields);
    
        // Minting Interface
        elements.incrementButton.addEventListener("click", function() {
            updateMintQuantity(1);
        });
        elements.decrementButton.addEventListener("click", function() {
            updateMintQuantity(-1);
        });

        // Minting Interface - Core Funcitonalities
        elements.mintNowButtonWeb3Modal.addEventListener("click", handleMintNowClickWeb3Modal);
        elements.logUserOut.addEventListener("click", signOut);
        elements.logUserOut3.addEventListener("click", signOut);

        // Minting Interface - Insufficient ETH Balance
        elements.copyAddressButton.addEventListener("click", copyEthAddress);
        elements.mercuryoLinkButton.addEventListener("click", handleMercuryoLinkClick);
        elements.scrollUpButton.addEventListener("click", handleScrollUpClick);
    }


    //
    //
    // Initial Function calls
    //
    //


    // Initial function calls
    checkSignInStatus();
    // GoogleAuth
    existingUserCheck();
    checkEmailVerificationStatus();
    checkPasswordResetFromURL();
    // Call the function to set up the subscription
    subscribeToLoginStateChanges();

    // Used to show submit-button-2 after reCAPTCHA V2 fallback 
    document.getElementById('submit-button-2').style.display = 'block';


    // Function for smooth scrolling
    function smoothScrollTo(element, offset) {
        const elementPosition = element.getBoundingClientRect().top + window.scrollY;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });
    }


    //
    //
    // Email Verification Re-Direct
    //
    //

    // Function to check the verification status from the URL and act accordingly
    function checkEmailVerificationStatus() {
        const urlParams = new URLSearchParams(window.location.search);
        const verificationStatus = urlParams.get('verification');

        if (verificationStatus === 'success') {

            const sessionId = localStorage.getItem('sessionId');
            if (sessionId){
                signOut ();
            } else {
                updateUIBasedOnSignInStatus(false);
            }

            // Show success message and scroll to element
            document.querySelector('.verification-success-message').style.display = 'block';

            // Call the smoothScrollTo function with a responsive offset
            smoothScrollTo(document.getElementById('ofc-mint'), window.innerWidth <= 600 ? 70 : 300);

        } else if (verificationStatus === 'failure') {
            // Show alert message
            alert('There was a problem verifying your email. If this error persists, please contact brenden@project-udsg.com.');
        }
    }


    //
    //
    // Check User Sign-in Status    +   Update UI
    //
    //


    // Function to check the sign-in status of the user | GoogleAuth
    async function checkSignInStatus() {

        // Check if there's a sessionId in the URL (from OAuth redirect)
        const urlParams = new URLSearchParams(window.location.search);
        const sessionIdFromUrl = urlParams.get('sessionId');
        const newUser = urlParams.get('newUser');
    
        if (sessionIdFromUrl) {
            // Store the sessionId in localStorage
            localStorage.setItem('sessionId', sessionIdFromUrl);
    
            // Store the newUser status in localStorage
            if (newUser === true || newUser === 'true') {
                localStorage.setItem('googleAuthNewUser', newUser);
            } else {
                localStorage.removeItem('googleAuthNewUser'); // Remove if it's not a new user
            }

            // Smooth-scroll on sign-in using Google:
            smoothScrollTo(document.getElementById('ofc-mint'), window.innerWidth <= 600 ? 70 : 340);
    
            // Clear the sessionId and newUser from the URL to keep it clean
            urlParams.delete('sessionId');
            urlParams.delete('newUser');
            window.history.replaceState({}, document.title, window.location.pathname);
        }

        const sessionId = localStorage.getItem('sessionId');

        if (!sessionId) {
            updateUIBasedOnSignInStatus(false);
            return;
        }

        try {
            const response = await fetch('https://user-management.ultimatedreamsetupgiveaway.io/session/verify', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ sessionId })
            });
            const data = await response.json();

            if (data.isValid) {
                updateUIBasedOnSignInStatus(true);

                // Setting login state to true
                // Use async getter to set login state
                const loginState = await loadLoginState();
                if (loginState) {
                    loginState.setLoginState(true);
                }

            } else {

                localStorage.removeItem('sessionId'); // Remove invalid session ID
                // Also remove googleAuthNewUser if session is invalid
                localStorage.removeItem('googleAuthNewUser');

                updateUIBasedOnSignInStatus(false);
            }
        } catch (error) {
            // Remove googleAuthNewUser on error if session is invalid
            localStorage.removeItem('googleAuthNewUser');
            updateUIBasedOnSignInStatus(false);

            console.error('Error during session verification:', error);
        }
    }

    // Function to update the UI based on the user's sign-in status
    function updateUIBasedOnSignInStatus(isSignedIn) {
        if (isSignedIn) {

            // New User | GoogleAuth
            const newUser = localStorage.getItem('googleAuthNewUser');
            // GoogleAuth
            if (newUser === true || newUser === 'true') {

                elements.mintingInterface.style.display = 'none';

                elements.signInContainer.style.display = 'block';
                elements.newUserMoreInfoSection.style.display = 'block';
                elements.completeAccountHeader.style.display = 'block';
                elements.logOutContainer3.style.display = 'block';


                // Show the Account Creation options | GoogleAuth
                elements.accountCreationOptions.style.display = 'none';
                // Hide Sign-in Interface
                elements.signinInterface.style.display = 'none';


                // Hide the loading animation
                elements.loadingAnimation.style.display = 'none';

            } else {

                // User is signed in and not a new user - show minting interface
                elements.signInContainer.style.display = 'none';
                elements.mintingInterface.style.display = 'block';
                fetchFirstName(); // Fetch and display the user's first name

                // Hide the loading animation
                elements.loadingAnimation.style.display = 'none';
            }

        } else {
            // User is not signed in - show sign-in container
            elements.signInContainer.style.display = 'block';
            elements.mintingInterface.style.display = 'none';

            // Show Sign-in Interface | GoogleAuth
            elements.signinInterface.style.display = 'block';
            // Hide complete account elements | GoogleAuth
            elements.newUserMoreInfoSection.style.display = 'none';
            elements.completeAccountHeader.style.display = 'none';
            elements.logOutContainer3.style.display = 'none';
            // Hide the Account Creation options | GoogleAuth
            elements.accountCreationOptions.style.display = 'none';

            // Hide the insufficient ETH balance UI
            elements.insufficientETHBalanceDiv.style.display = 'none';

            // Hide the loading animation
            elements.loadingAnimation.style.display = 'none';

            // Check session status from localStorage | GoogleAuth | OneTap
            const sessionId = localStorage.getItem('sessionId');

            (async function() {
                if (window.innerWidth > 768 && !sessionId) {
                    await initializeOneTap(); // Wait for the One Tap initialization to complete
            
                    // GoogleAuth | One-Tap
                    google.accounts.id.prompt(); // Manually trigger the One Tap prompt after initialization
                }
            })();
        }
    }


    //
    //
    // Function to handle credential response from GoogleAuth One Tap
    //
    //


    function handleCredentialResponse(response) {
        console.log('Google One Tap Response:', response);

        // Display the loading animation
        elements.loadingAnimation.style.display = 'flex';

        const idToken = response.credential;

        // Send the ID token to your backend for verification and authentication | via AJAX | GoogleAuth
        fetch('https://user-management.ultimatedreamsetupgiveaway.io/auth/google-auth', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json' // Indicate that we expect a JSON response
            },
            body: JSON.stringify({ credential: idToken })
        })
        .then(res => res.json())
        .then(data => {
            if (data.sessionId) {

                // Store the newUser status in localStorage
                localStorage.setItem('googleAuthNewUser', data.newUser);
                // Store session ID as needed (e.g., in localStorage)
                localStorage.setItem('sessionId', data.sessionId);

                // User is authenticated - update UI accordingly
                console.log('User authenticated successfully.');
                checkSignInStatus();

            } else {
                // Handle authentication failure
                checkSignInStatus();
                console.error('Authentication failed:', data.error);
                showError('Authentication failed. Please try again.');
            }
        })
        .catch(error => {
            checkSignInStatus();
            console.error('Error during authentication:', error);
            showError('An error occurred. Please try again.');
        });
    }

    // Function to display error messages
    function showError(message) {
        const errorMessageElement = document.getElementById('error-message');
        errorMessageElement.textContent = message;
        errorMessageElement.style.display = 'block';
        console.log('Displayed error message:', message);
    }


    // Function to initialize Google One Tap | GoogleAuth
    async function initializeOneTap() {

        try {
            if (typeof google !== 'undefined' && google.accounts && google.accounts.id) {
                google.accounts.id.initialize({
                    client_id: '982682203162-boc3ed5ekdltffmrghfim64887718ojk.apps.googleusercontent.com', // Replace with your actual Client ID
                    callback: handleCredentialResponse,
                    auto_select: false, // Prevents automatic sign-in
                    cancel_on_tap_outside: false // Determines behavior when clicking outside the prompt
                });

                // Optionally, render the One Tap prompt
                // google.accounts.id.renderButton(document.getElementById('buttonDiv'), { theme: 'outline', size: 'large' });

                // Trigger the One Tap prompt
                google.accounts.id.prompt();
            } else {
                throw new Error('Google accounts library not loaded.');
            }
        } catch (error) {
            console.error('An error occurred while initializing Google One Tap:', error.message);
        }
    }


    //
    //
    // Display  +   Main Sign-In Interface
    //
    //


    // Function to show the sign-in form and hide other sections
    function showSignInInterface() {

        // Clear Email Address && Password fields
        elements.signInInputFields.querySelector("#email").value = '';
        elements.signInInputFields.querySelector("#password").value = '';

        // Hide the account creation section and related elements
        elements.createAccountSection.style.display = 'none';
        elements.createAccountHeader.style.display = 'none';

        // GoogleAuth | Remove the Email & Password Sign-In fields
        elements.signInInputFields.style.display = 'none';
        elements.signInEmailPasswordButton.style.display = 'inline-block';

        // Show the sign-in Interface
        elements.signinInterface.style.display = 'block';

        // Show the Account Creation options | GoogleAuth
        elements.accountCreationOptions.style.display = 'none';

        // Logic to Detect "In-App" browsers and hide the google sign in button
        if (detectInAppBrowser()) {
            handleInAppBrowser();
        }
    }


    //
    //
    // Handle Register Now Link Clicks       +       SHOW ACCOUNT FORM
    //
    //

    
    // Event handler for the 'Register Now' link click
    function handleRegisterNowClick() {
        
        // Hide the sign-in Interface
        elements.signinInterface.style.display = 'none';

        // Show the account creation section and related elements
        elements.createAccountSection.style.display = 'block';
        elements.createAccountHeader.style.display = 'block';
        elements.backToSignInLinkSecondPage.style.display = 'block';
        elements.backToSignInText.style.display = 'flex';

        // Show the Account Creation options | GoogleAuth
        elements.accountCreationOptions.style.display = 'block';

        // Hide the Account Creation form initially | GoogleAuth
        elements.accountCreationForm.style.display = 'none';

        // Logic to Detect "In-App" browsers and hide the google sign in button
        if (detectInAppBrowser()) {
            handleInAppBrowser();
        }
    }
    

    //
    //
    // GoogleAuth | Display Handler
    //
    //

    
    // Event handler for showing the email/password form | GoogleAuth
    function showEmailPasswordForm() {
        // Hide the Google Auth button
        elements.accountCreationOptions.style.display = 'none';

        // Show the full Account Creation form
        elements.accountCreationForm.style.display = 'block';
    }

    // Event handler for showing the email/password form | GoogleAuth
    function showSignInEmailPasswordFields() {
        // Hide the Sign-In with button
        elements.signInEmailPasswordButton.style.display = 'none';

        // Show the full Account Creation form
        elements.signInInputFields.style.display = 'flex';
    }


    //
    //
    // Sign In   +   Sign Out
    //
    //

    
    // Event handler for SIGNING-IN THE USER
    async function handleUserSignIn(event) {
        event.preventDefault();

        // Display the loading animation
        elements.loadingAnimation.style.display = 'flex';

        // Capture user input
        const email = elements.signInInputFields.querySelector("#email").value;
        const password = elements.signInInputFields.querySelector("#password").value;

        // Create an object with the user credentials
        const userCredentials = { email, password };

        try {
            const response = await fetch('https://user-management.ultimatedreamsetupgiveaway.io/user/sign-in', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(userCredentials)
            });

            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.message || 'Network response was not ok');
            }

            const sessionData = await response.json();
            if (sessionData.sessionId) {
                // Update the UI to reflect sign-in status
                localStorage.setItem('sessionId', sessionData.sessionId);
                updateUIBasedOnSignInStatus(true);

                // Setting login state to true
                // Use async getter to set login state
                const loginState = await loadLoginState();
                if (loginState) {
                    loginState.setLoginState(true);
                }

                // Smooth-scroll on sign-in:
                smoothScrollTo(document.getElementById('ofc-mint'), window.innerWidth <= 600 ? 70 : 340);

            } else {
                throw new Error('Session ID not provided');
            }
        } catch (error) {
            console.error('Error:', error);
            // Check if the error message contains "GOOGLE_AUTH_ONLY" | GoogleAuth
            if (error.message.includes('Please sign in using your Google account.')) {
                alert('You have previously signed up using Google. Please use the "Sign in with Google" option.');
            } else if (error.message.includes('Account not verified. A new verification code has been sent to your email.')) {
                alert('Your account has not been verified yet. A NEW verification link has been sent to your email. Contact brenden@project-udsg.com if you need further assistance.');
            } else {
                // Default error message for other types of errors
                alert(error.message);
            }
        } finally {
            // Hide the loading animation in both success and error cases
            elements.loadingAnimation.style.display = 'none';
        }
    }

    // Function to handle user sign-out
    async function signOut() {
        const sessionId = localStorage.getItem('sessionId');

        if (sessionId) {
            try {
                const response = await fetch('https://user-management.ultimatedreamsetupgiveaway.io/session/end', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ sessionId })
                });

                if (response.ok) {
                    console.log('Sign out successful.');
                } else {
                    console.error('Sign out failed.');
                }
            } catch (error) {
                console.error('Error during sign out:', error);
            }
        }

        // Clear session ID and other relevant data from storage
        localStorage.removeItem('sessionId');
        localStorage.removeItem('tokens');

        // Clear Email Address && Password fields
        elements.signInInputFields.querySelector("#email").value = '';
        elements.signInInputFields.querySelector("#password").value = '';

        // Clear NFT Display UI
        // Wrapper for handling transition / display of NFT
        elements.accountCTADiv.style.display = 'none';
        elements.loadingSpinnerNFT.style.display = 'none';
        elements.wrapperDisplayDiv.style.display = 'none';
        elements.insufficientETHBalanceDiv.style.display = 'none';

        // Update the UI based on sign-in status
        updateUIBasedOnSignInStatus(false);

        // Call the smoothScrollTo function with a responsive offset
        smoothScrollTo(document.getElementById('ofc-mint'), window.innerWidth <= 600 ? 70 : 300);

        // Setting login state to false using the async getter
        const loginState = await loadLoginState();
        if (loginState) {
            loginState.setLoginState(false);
        }

        // Optionally, redirect to the sign-in page or perform other actions
    }


    //
    //
    // GoogleAuth | If user previously signed in with email / password and is now signing in with google.
    //
    //


    function existingUserCheck() {

        // Parse the query parameters from the URL
        const urlParams = new URLSearchParams(window.location.search);
        
        // Check if the user needs to confirm switching to Google sign-in
        if (urlParams.get('confirmGoogleSignIn') === 'true') {

            // Call the smoothScrollTo function with a responsive offset
            smoothScrollTo(document.getElementById('ofc-mint'), window.innerWidth <= 600 ? 70 : 300);

            const sessionId = urlParams.get('idToken');
            if (sessionId) {
                // Show a modal or confirmation dialog to the user
                showModal("You previously signed in with an email & password. Signing in with Google will remove your ability to sign in via email & password. Do you want to proceed?", sessionId);
    
                // Remove the `confirmGoogleSignIn` and `session` parameters, but keep others like `error`
                urlParams.delete('confirmGoogleSignIn');
                urlParams.delete('idToken');
    
                // Build the cleaned URL
                const cleanUrl = window.location.pathname + (urlParams.toString() ? '?' + urlParams.toString() : '');
    
                // Update the browser's URL without reloading the page
                window.history.replaceState(null, '', cleanUrl);
            }
        }
    }

    // GoogleAuth | Show the modal when needed (e.g., on existingUserCheck or confirmation)
    function showModal(message, idToken) {
        // Get the existing modal element
        const modal = document.getElementById('confirmationModal');
        const modalMessage = document.getElementById('modalMessage');

        // Set the modal message
        modalMessage.textContent = message;

        modal.style.display = 'block';
    
        // Handle the user's response
        document.getElementById('confirmGoogleSignIn').addEventListener('click', () => {

            // Hide the modal
            modal.style.display = 'none';
        
            // Send the POST request to the backend
            fetch('https://user-management.ultimatedreamsetupgiveaway.io/auth/google-auth', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json' // Indicate that we expect a JSON response
                },
                body: JSON.stringify({
                    proceedWithGoogle: true,
                    session: idToken
                })
            })
            .then(response => response.json())
            .then(data => {
                // Handle errors from the backend
                if (data.error) {
                    console.error('Error:', data.error);
                    if (data.error === 'session_expired') {
                        alert('Your session has expired. Please try signing in again. Contact brenden@project-udsg.com if this error persists.');
                    } else if (data.error === 'google_auth_failed') {
                        alert('Google authentication failed. Please try again. Contact brenden@project-udsg.com if this error persists.');
                    } else if (data.error === 'missing_id_token') {
                        alert('Missing IdToken. Please try again. Contact brenden@project-udsg.com if this error persists.');
                    } else {
                        alert('An unknown error occurred. Contact brenden@project-udsg.com if this error persists.');
                    }
                    return; // Stop execution if an error occurs
                }
    
                // Store sessionId and newUserFlag in localStorage if no errors
                if (data.sessionId) {
                    localStorage.setItem('sessionId', data.sessionId);
                }
                if (data.newUser) {
                    localStorage.setItem('newUserFlag', data.newUser);
                }
    
                updateUIBasedOnSignInStatus(true);
                updateUIBasedOnWalletOption();
                // Call the smoothScrollTo function with a responsive offset
                smoothScrollTo(document.getElementById('ofc-mint'), window.innerWidth <= 600 ? 70 : 340);
            })
            .catch((error) => {
                console.error('Request failed:', error);
                alert('A network error occurred. Please try again. Contact brenden@project-udsg.com if this error persists.');
            });
        });
    
        document.getElementById('cancelGoogleSignIn').addEventListener('click', () => {
            // Optionally handle cancellation (e.g., redirect to account page)
            modal.style.display = 'none';
        });

        // Close modal if clicked outside of the content
        window.addEventListener('click', (event) => {
            if (event.target === modal) {
                modal.style.display = 'none';
            }
        });
    }
        
    
    //
    //
    // Form Submission - Utilities
    //
    //


    // Function to subscribe user to mailchimp if the box was checked
    function signUpUserToMailchimp(userInfo) {
        fetch('https://user-management.ultimatedreamsetupgiveaway.io/mailchimp/sign-up', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ userInfo }) // Assuming your backend expects an object with a userInfo property
        })
        .then(response => {
            if (!response.ok) {
                // Handle non-2xx HTTP responses
                throw new Error('Failed to sign up user to Mailchimp.');
            }
            return response.json();
        })
        .then(data => {
            console.log('Mailchimp sign-up successful:', data);
            // Optionally, display a success message or take other actions upon successful sign-up
        })
        .catch(error => {
            console.error('Error signing up user to Mailchimp:', error);
            // Optionally, display an error message to the user
        });
    }

    // Function to subscribe user to vortext if the box was checked
    function signUpUserToVortext(userInfo) {
        fetch('https://user-management.ultimatedreamsetupgiveaway.io/vortext/sign-up', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ userInfo })
        })
        .then(response => {
            if (!response.ok) {
                // Handle non-2xx HTTP responses
                throw new Error('Failed to sign up user to Vortext.');
            }
            return response.json();
        })
        .then(data => {
            console.log('Vortext sign-up successful:', data);
            // Optionally, display a success message or take other actions upon successful sign-up
        })
        .catch(error => {
            console.error('Error signing up user to Vortext:', error);
            // Optionally, display an error message to the user
        });
    }
    
    // Logic to handle display of the eyeball icons
    document.querySelectorAll('.toggle-password').forEach(icon => {
        icon.addEventListener('click', function() {
            const targetInput = document.querySelector(this.getAttribute('data-target'));
            const icon = this.querySelector('i'); // Get the icon inside the span
            if (targetInput.type === 'password') {
                targetInput.type = 'text';
                icon.classList.remove('fa-eye-slash'); // Remove the closed eye icon
                icon.classList.add('fa-eye'); // Add the open eye icon
            } else {
                targetInput.type = 'password';
                icon.classList.remove('fa-eye'); // Remove the open eye icon
                icon.classList.add('fa-eye-slash'); // Add the closed eye icon
            }
        });
    });
    
    // Function to sanitize input by removing commas
    function sanitizeInput(input) {
        return input.replace(/,/g, ''); // Remove all commas
    }

    // Function to sanitize and format phone numbers to ensure they contain exactly 10 digits
    function formatPhoneNumber(input) {
        if (!input) {
            return null; // Return null if the input is empty | GoogleAuth
        }

        // Remove all non-digit characters
        let cleanedInput = input.replace(/\D/g, '');

        // If the number is longer than 10 digits, assume the first digits are the country code and remove them
        if (cleanedInput.length > 10) {
            cleanedInput = cleanedInput.slice(-10); // Retain the last 10 digits
        }

        return cleanedInput;
    }


    //
    //
    // Main - Form Submission
    //
    //


    // Event handler for submitting the account creation form
    function handleAccountFormSubmit(event) {
        event.preventDefault();

        // Gather form data
        const userData = {
            email: document.getElementById("formEmail").value,
            password: document.getElementById("formPassword").value,
            firstName: document.getElementById("firstName").value,
            lastName: document.getElementById("lastName").value,
            phone: formatPhoneNumber(document.getElementById("phone").value),
            // GoogleAuth
            // GoogleAuth
            provinceState: sanitizeInput(document.getElementById("provinceState").value),
            country: sanitizeInput(document.getElementById("country").value),
            // GoogleAuth
            affiliateCode: null, // Setting to null by default
            adTrackingCampaign: null, // Setting to null by default
            adTrackingCampaignAffiliate: null, // Setting to null by default
            organicCampaign: null, // Setting null by default
            recaptchaToken: null, // This will hold the reCAPTCHA token | Used for V2 fallback
            recaptchaVersion: 'v3' // Default to v3
        };

        // Logic to determind affiliate commission
        const affiliateCode = localStorage.getItem('affiliateCode');

        // Used for Tracking Affilaite Sales Data:
        const adTrackingCampaignAffiliate = localStorage.getItem('adTrackingCampaignAffiliate');

        // Logic to determind ad tracking campaign
        const adTrackingCampaign = localStorage.getItem('adTrackingCampaign');

        // Logic to track organic campaigns
        const organicCampaign = localStorage.getItem('organicCampaign');

        // If the campaign types are present, update the userData accordingly
        if (affiliateCode) {
            userData.affiliateCode = affiliateCode;
        }
        if (adTrackingCampaignAffiliate) {
            userData.adTrackingCampaignAffiliate = adTrackingCampaignAffiliate;
        }
        if (adTrackingCampaign) {
            userData.adTrackingCampaign = adTrackingCampaign;
        }
        if (organicCampaign && organicCampaign.trim() !== "") {
            userData.organicCampaign = organicCampaign;
        }

        // Default to 'organic' only if no adTrackingCampaign, affiliateCode, or organicCampaign is found
        if (!affiliateCode && !adTrackingCampaign && (!organicCampaign || organicCampaign.trim() === "")) {
            const organicCampaignToAssign = 'organic';
            userData.organicCampaign = organicCampaignToAssign;
        }

        // Password confirmation value (not included in userData)
        const passwordConfirm = document.getElementById("formPasswordConfirm").value;

        // Perform client-side validation based on the original script's logic
        if (!validateAccountForm(userData, passwordConfirm)) {
            return; // Stop form submission if validation fails
        }

        // Function to validate the form data
        function validateAccountForm(userData, passwordConfirm) {

            if (userData.password.length < 6) {
                alert("Password must be at least 6 characters long.");
                return;
            }
            if (!/[A-Z]/.test(userData.password)) {
                alert("Password must contain at least one uppercase letter.");
                return;
            }
            if (!/[a-z]/.test(userData.password)) {
                alert("Password must contain at least one lowercase letter.");
                return;
            }
            if (!/\d/.test(userData.password)) {
                alert("Password must contain at least one number.");
                return;
            }

            // Check if passwords match
            if (userData.password !== passwordConfirm) {
                alert("Passwords do not match. Please try again.");
                return;
            }
        
            // Validate email format
            const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
            if (!emailRegex.test(userData.email)) {
                alert("Please enter a valid email address.");
                return;
            }
        
            // Validate phone number format with optional hyphens, spaces, and country code | Optional | GoogleAuth
            const phoneRegex = /^(\+?\d{1,3}\s?)?(\d{3})[-\s]?(\d{3})[-\s]?(\d{4})$/;
            if (userData.phone && !phoneRegex.test(userData.phone)) {
                alert("Please enter a valid phone number (e.g., 705-111-2222, 705 111 2222, 7051112222).");
                return;
            }

            // Validate First Name
            if (!userData.firstName.trim()) {
                alert("Please enter a first name.");
                return;
            }

            // Validate Last Name
            if (!userData.lastName.trim()) {
                alert("Please enter a last name.");
                return;
            }

            // GoogleAuth

            // GoogleAuth

            // Validate Province/State
            if (!userData.provinceState.trim()) {
                alert("Please enter a province or state.");
                return;
            }

            // Validate Country
            if (!userData.country.trim()) {
                alert("Please enter a country.");
                return;
            }

            // GoogleAuth

            // Validate Terms and Conditions checkbox
            const termsCheckbox = document.getElementById("terms");
            if (!termsCheckbox.checked) {
                alert("Please agree to the Terms and Conditions.");
                return;
            }

            // Return true if all validations pass
            return true;
        }

        // Display the loading animation
        elements.loadingAnimation.style.display = 'flex';

        // Execute reCAPTCHA and get the token
        grecaptcha.ready(function() {
            grecaptcha.execute('6LfiMz0qAAAAANM5k9rEEWZlGNfeghckvDiKAMDB', { action: 'submit' }).then(function(token) {
                // Add the token to userData
                userData.recaptchaToken = token;
                userData.recaptchaVersion = 'v3';

                // Send data to your backend server
                fetch('https://user-management.ultimatedreamsetupgiveaway.io/create-account', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(userData),
                })
                .then(response => response.json())
                .then(data => {
                    if (data.message === 'Registration successful') {

                        // Meta Pixel tracking user registrations
                        fbq('track', 'CompleteRegistration');

                        // TikTok Pixel tracking user registrations
                        // Create a new object for Pixel data
                        let tikTokUserData = {
                            email: userData.email, // Extract email from userData
                            external_id: userData.email // Use email as the external_id by default
                        };

                        // Check if the phone number is present in userData, then add it to tikTokUserData
                        if (data.phone && data.phone !== '+15555555555') {
                            tikTokUserData.phone_number = data.phone; // Add phone number if available
                        }

                        // Now call ttq.identify with the tikTokUserData object
                        ttq.identify(tikTokUserData);

                        // Fire the event (CompleteRegistration)
                        ttq.track('CompleteRegistration', {
                            "contents": [
                                {
                                    "content_id": "UDSG #01",
                                    "content_type": "product",
                                    "content_name": "UDSG #01"
                                }
                            ]
                        });

                        // Hide the loading animation
                        elements.loadingAnimation.style.display = 'none';

                        // If user opted in for the email list
                        const gpuSignUpCheckbox = document.getElementById('gpu-sign-up');
                        if (gpuSignUpCheckbox.checked) {
                            // Prepare the userInfo object for Mailchimp
                            const userInfoForMailchimp = {
                                email: userData.email,
                                givenName: userData.firstName,
                                familyName: userData.lastName,
                                // GoogleAuth
                                address: `${data.provinceState}, ${data.country}` // GoogleAuth
                            };

                            // Include phone number in Mailchimp only if it's not +15555555555
                            if (data.phone !== '+15555555555') {
                                userInfoForMailchimp.phoneNumber = data.phone;
                            }

                            // Call the function to sign up the user to Mailchimp
                            signUpUserToMailchimp(userInfoForMailchimp);

                            // GoogleAuth
                            // Skip Vortext signup if the phone number is +15555555555
                            if (data.phone !== '+15555555555') {
                                // Debugging / verifiying proper format:
                                console.log('Vortext Phone:', data.phone.startsWith('+') ? data.phone : `+1${data.phone}`);
                                // Prepare the userInfo object for Vortext
                                const userInfoForVortext = {
                                    phone: data.phone.startsWith('+') ? data.phone : `+1${data.phone}`, // GoogleAuth
                                    firstname: userData.firstName,
                                    lastname: userData.lastName,
                                    email: userData.email,
                                    // GoogleAuth
                                    state: data.provinceState, // GoogleAuth
                                    // GoogleAuth
                                    country: data.country, // GoogleAuth
                                    tags: "UserDashboard"
                                };

                                // Call the function to sign up the user to Vortext
                                signUpUserToVortext(userInfoForVortext);
                            }
                        }

                        // Display the email verification message and Sign In Form
                        document.querySelector('.email-verification-message').style.display = 'block';
                        showSignInInterface();

                        // Call the smoothScrollTo function with a responsive offset
                        smoothScrollTo(document.getElementById('ofc-mint'), window.innerWidth <= 600 ? 70 : 300);

                    } else if (data.showRecaptchaV2Fallback) {
                        // Handle case where reCAPTCHA v2 needs to be shown
                        // Display the reCAPTCHA v2 container
                        document.getElementById('recaptcha-container').style.display = 'flex';
                        document.getElementById('submit-button-2').style.display = 'none'; // Optionally hide the submit button

                        // Attach the callback for the reCAPTCHA v2 completion
                        window.onRecaptchaV2Submit = function(token) {
                            userData.recaptchaToken = token; // Add the v2 token to userData
                            submitFormWithRecaptchaV2(userData); // Submit the form with the v2 token
                        };

                        // Force a small delay to ensure the display changes happen before the alert
                        setTimeout(function() {

                            alert(data.message);

                            // Hide the loading animation
                            elements.loadingAnimation.style.display = 'none';

                            // Call the smoothScrollTo function with a responsive offset
                            smoothScrollTo(document.getElementById('info-button-container'), window.innerWidth <= 600 ? 250 : 600);

                        }, 100);
        
                    } else {
                        // Hide the loading animation
                        elements.loadingAnimation.style.display = 'none';
        
                        // If the registration failed, alert the user
                        throw new Error(data.message || 'Registration failed');
                    }
                })
                .catch((error) => {
                    // Hide the loading animation
                    elements.loadingAnimation.style.display = 'none';
        
                    console.error('Error:', error);
                    alert(`Error: ${error.message}. If this error persists, please contact brenden@project-udsg.com`);
                });
            });
        });
    }

    function submitFormWithRecaptchaV2(userData) {

        // Update userData with the V2 version
        userData.recaptchaVersion = 'v2';

        // Send data to your backend server with reCAPTCHA v2 token
        fetch('https://user-management.ultimatedreamsetupgiveaway.io/create-account', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userData),
        })
        .then(response => response.json())
        .then(data => {
            if (data.message === 'Registration successful') {

                // Meta Pixel tracking user registrations
                fbq('track', 'CompleteRegistration');

                // TikTok Pixel tracking user registrations
                // Create a new object for Pixel data
                let tikTokUserData = {
                    email: userData.email, // Extract email from userData
                    external_id: userData.email // Use email as the external_id by default
                };

                // Check if the phone number is present in userData, then add it to tikTokUserData
                if (data.phone && data.phone !== '+15555555555') {
                    tikTokUserData.phone_number = data.phone; // Add phone number if available
                }

                // Now call ttq.identify with the tikTokUserData object
                ttq.identify(tikTokUserData);

                // Fire the event (CompleteRegistration)
                ttq.track('CompleteRegistration', {
                    "contents": [
                        {
                            "content_id": "UDSG #01",
                            "content_type": "product",
                            "content_name": "UDSG #01"
                        }
                    ]
                });

                // Hide the loading animation
                elements.loadingAnimation.style.display = 'none';
    
                // If user opted in for the email list
                const gpuSignUpCheckbox = document.getElementById('gpu-sign-up');
                if (gpuSignUpCheckbox.checked) {
                    // Prepare the userInfo object for Mailchimp
                    const userInfoForMailchimp = {
                        email: userData.email,
                        givenName: userData.firstName,
                        familyName: userData.lastName,
                        // GoogleAuth
                        address: `${data.provinceState}, ${data.country}` // GoogleAuth
                    };

                    // Include phone number in Mailchimp only if it's not +15555555555
                    if (data.phone !== '+15555555555') {
                        userInfoForMailchimp.phoneNumber = data.phone;
                    }
    
                    // Call the function to sign up the user to Mailchimp
                    signUpUserToMailchimp(userInfoForMailchimp);
    
                    // GoogleAuth
                    // Skip Vortext signup if the phone number is +15555555555
                    if (data.phone !== '+15555555555') {
                        // Prepare the userInfo object for Vortext
                        const userInfoForVortext = {
                            phone: data.phone.startsWith('+') ? data.phone : `+1${data.phone}`, // GoogleAuth
                            firstname: userData.firstName,
                            lastname: userData.lastName,
                            email: userData.email,
                            // GoogleAuth
                            state: data.provinceState, // GoogleAuth
                            // GoogleAuth
                            country: data.country, // GoogleAuth
                            tags: "UserDashboard"
                        };

                        // Call the function to sign up the user to Vortext
                        signUpUserToVortext(userInfoForVortext);
                    }
                }
    
                // Display the email verification message and Sign In Form
                document.querySelector('.email-verification-message').style.display = 'block';
                showSignInInterface();
    
                // Call the smoothScrollTo function with a responsive offset
                smoothScrollTo(document.getElementById('ofc-mint'), window.innerWidth <= 600 ? 70 : 300);
    
            } else {
                // Hide the loading animation
                elements.loadingAnimation.style.display = 'none';

                // Keeping submit-button-2 hidden after reCAPTCHA V2 fallback failure to avoid bots re-trying
                // Also hiding reCAPTCHA V2
                document.getElementById('recaptcha-container').style.display = 'none';
    
                // If the registration failed, alert the user
                throw new Error(data.message || 'Registration failed');
            }
        })
        .catch((error) => {
            // Hide the loading animation
            elements.loadingAnimation.style.display = 'none';

            // Keeping submit-button-2 hidden after reCAPTCHA V2 fallback failure to avoid bots re-trying
            // Also hiding reCAPTCHA V2
            document.getElementById('recaptcha-container').style.display = 'none';
    
            console.error('Error:', error);
            alert(`Error: ${error.message}. If this error persists, please contact brenden@project-udsg.com`);
        });
    }




    //
    //
    // Complete Account Information - Form Submission | GoogleAuth
    //
    //




    // Click handler
    document.getElementById('additionalInfoSubmitButton').addEventListener('click', handleCompleteAccountFormSubmit);

    // Event handler for submitting the complete account creation form
    function handleCompleteAccountFormSubmit(event) {
        event.preventDefault();
        
        // Check session status from localStorage
        const sessionId = localStorage.getItem('sessionId');

        // Gather form data
        const userData = {

            sessionId: sessionId,

            phone: formatPhoneNumber(document.getElementById("phoneNumber").value),

            provinceState: sanitizeInput(document.getElementById("provinceStateField").value),
            country: sanitizeInput(document.getElementById("countryField").value),

            affiliateCode: null, // Setting to null by default
            adTrackingCampaign: null, // Setting to null by default
            adTrackingCampaignAffiliate: null, // Setting to null by default
            organicCampaign: null // Setting null by default
        };

        // Logic to determind affiliate commission
        const affiliateCode = localStorage.getItem('affiliateCode');

        // Used for Tracking Affilaite Sales Data:
        const adTrackingCampaignAffiliate = localStorage.getItem('adTrackingCampaignAffiliate');

        // Logic to determind ad tracking campaign
        const adTrackingCampaign = localStorage.getItem('adTrackingCampaign');

        // Logic to track organic campaigns
        const organicCampaign = localStorage.getItem('organicCampaign');

        // If the campaign types are present, update the userData accordingly
        if (affiliateCode) {
            userData.affiliateCode = affiliateCode;
        }
        if (adTrackingCampaignAffiliate) {
            userData.adTrackingCampaignAffiliate = adTrackingCampaignAffiliate;
        }
        if (adTrackingCampaign) {
            userData.adTrackingCampaign = adTrackingCampaign;
        }
        if (organicCampaign && organicCampaign.trim() !== "") {
            userData.organicCampaign = organicCampaign;
        }

        // Default to 'organic' only if no adTrackingCampaign, affiliateCode, or organicCampaign is found
        if (!affiliateCode && !adTrackingCampaign && (!organicCampaign || organicCampaign.trim() === "")) {
            const organicCampaignToAssign = 'organic';
            userData.organicCampaign = organicCampaignToAssign;
        }

        // Perform client-side validation based on the original script's logic
        if (!validateAccountForm(userData)) {
            return; // Stop form submission if validation fails
        }

        // Function to validate the form data
        function validateAccountForm(userData) {
        
            // Validate phone number format with optional hyphens, spaces, and country code | Optional | GoogleAuth
            const phoneRegex = /^(\+?\d{1,3}\s?)?(\d{3})[-\s]?(\d{3})[-\s]?(\d{4})$/;
            if (userData.phone && !phoneRegex.test(userData.phone)) {
                alert("Please enter a valid phone number (e.g., 705-111-2222, 705 111 2222, 7051112222).");
                return;
            }

            // Validate Province/State
            if (!userData.provinceState.trim()) {
                alert("Please enter a province or state.");
                return;
            }

            // Validate Country
            if (!userData.country.trim()) {
                alert("Please enter a country.");
                return;
            }

            // Validate Terms and Conditions checkbox
            const termsCheckbox = document.getElementById("termsConditions");
            if (!termsCheckbox.checked) {
                alert("Please agree to the Terms and Conditions.");
                return;
            }

            // Return true if all validations pass
            return true;
        }

        // Display the loading animation
        elements.loadingAnimation.style.display = 'flex';


        // Update Account Settings via POST request to the backend
        fetch('https://user-management.ultimatedreamsetupgiveaway.io/account/update-google-account-settings', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userData),
        })
        .then(response => {
            if (!response.ok) {
                return response.json().then(err => {
                    throw err;
                });
            }
            return response.json();
        })
        .then(data => {

            // Check if the backend response indicates successful registration
            if (data.message === 'Account information updated successfully') {

                // Meta Pixel tracking user registrations
                fbq('track', 'CompleteRegistration');

                // TikTok Pixel tracking user registrations
                // Create a new object for Pixel data
                let tikTokUserData = {
                    email: data.email, // Extract email from userData
                    external_id: data.email // Use email as the external_id by default
                };

                // Check if the phone number is present in userData, then add it to tikTokUserData
                if (data.phone && data.phone !== '+15555555555') {
                    tikTokUserData.phone_number = data.phone; // Add phone number if available
                }

                // Now call ttq.identify with the tikTokUserData object
                ttq.identify(tikTokUserData);

                // Fire the event (CompleteRegistration)
                ttq.track('CompleteRegistration', {
                    "contents": [
                        {
                            "content_id": "UDSG #01",
                            "content_type": "product",
                            "content_name": "UDSG #01"
                        }
                    ]
                });

                // Only proceed with email/SMS list sign-up if the user opted in
                const gpuSignUpCheckbox2 = document.getElementById('emailSmsSignUpField');
                if (gpuSignUpCheckbox2.checked) {
                    // Prepare the userInfo object for Mailchimp
                    const userInfoForMailchimp = {
                        email: data.completedAccountInfo.email, // Now fetched from the backend
                        givenName: data.completedAccountInfo.given_name,
                        familyName: data.completedAccountInfo.family_name,
                        address: `${data.completedAccountInfo.province_state}, ${data.completedAccountInfo.country}`
                    };

                    // Only add phone number to Mailchimp if it's not null
                    if (data.completedAccountInfo.phone_number) {
                        userInfoForMailchimp.phoneNumber = data.completedAccountInfo.phone_number;
                    }

                    // Call the function to sign up the user to Mailchimp
                    signUpUserToMailchimp(userInfoForMailchimp);

                    // Vortext logic: Sign up to Vortext only if phone number is not null
                    if (data.completedAccountInfo.phone_number) {
                        // Prepare the userInfo object for Vortext
                        const userInfoForVortext = {
                            phone: data.completedAccountInfo.phone_number.startsWith('+') 
                                ? data.completedAccountInfo.phone_number 
                                : `+1${data.completedAccountInfo.phone_number}`,
                            firstname: data.completedAccountInfo.given_name,
                            lastname: data.completedAccountInfo.family_name,
                            email: data.completedAccountInfo.email,
                            state: data.completedAccountInfo.province_state,
                            country: data.completedAccountInfo.country,
                            tags: "UserDashboard"
                        };

                        // Call the function to sign up the user to Vortext
                        signUpUserToVortext(userInfoForVortext);
                    }
                }

                localStorage.removeItem('googleAuthNewUser'); // Remove as user is not a new user

                updateUIBasedOnSignInStatus(true);
                updateUIBasedOnWalletOption();
                // Call the smoothScrollTo function with a responsive offset
                smoothScrollTo(document.getElementById('ofc-mint'), window.innerWidth <= 600 ? 70 : 340);

                // Display the loading animation
                elements.loadingAnimation.style.display = 'none';
            }
        })
        .catch(error => {
            // Handle 400 errors (or others)
            if (error.message === "Session ID is required") {
                alert("Your session has expired. Please sign in again to proceed.");
                updateUIBasedOnSignInStatus(false);
            } else {
                alert("Error: Please try again. Contact brenden@project-udsg.com if this error persists.");
                console.error('Error updating account:', error);
            }
            elements.loadingAnimation.style.display = 'none';  // Hide the loading animation if an error occurs
        });
    }


    //
    //
    // Forgot Password  +   Modal
    //
    //


    // Function to toggle the display of the forgot password modal
    function toggleForgotPasswordModal() {
        elements.forgotPasswordModal.style.display = elements.forgotPasswordModal.style.display === 'block' ? 'none' : 'block';
    }

    // Function to hide the forgot password modal
    function hideForgotPasswordModal() {
        elements.forgotPasswordModal.style.display = 'none';
    }

    // Event handler for submitting the forgot password request | GoogleAuth | Change it all over to this:
    function handleSubmitForgotPassword() {

        const email = elements.forgotPasswordModal.querySelector("#forgotEmail").value;

        fetch('https://user-management.ultimatedreamsetupgiveaway.io/user/forgot-password', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email })
        })
        .then(response => response.json()) // Convert response to JSON
        .then(data => {
            // Since we have parsed the response as JSON, we check the message from the `data`
            if (data.message === 'google_account') {
                elements.forgotPasswordModal.style.display = 'none'; // Hide modal
                alert("You cannot change your password since you have a Google account type. Please sign in with your Google account.");
            } else if (data.message === 'Password reset instructions sent to email') {
                elements.forgotPasswordModal.style.display = 'none'; // Hide modal
                alert("Password reset instructions have been sent to your email.");
            } else if (data.message === "User not found") {
                elements.forgotPasswordModal.style.display = 'none'; // Hide modal
                alert("User not found. Please register for an account.");
            } else {
                // Handle other generic errors
                alert("There was a problem sending the reset instructions. Please try again.");
            }
        })
        .catch(error => {
            console.error('Error during forgot password request:', error);
            alert('An error occurred while trying to send the reset instructions. Please try again.');
        });
    }


    //
    //
    // Reset Password  +   Modal
    //
    //

    
    let currentEmail = null; // Declare at a higher scope
    let currentVerificationCode = null; // Declare at a higher scope
    
    function checkPasswordResetFromURL() {
        const urlParams = new URLSearchParams(window.location.search);
        const verificationCode = urlParams.get('code');
        const email = urlParams.get('email');
        const isResetPassword = urlParams.get('resetPassword');
    
        if (verificationCode && email && isResetPassword === 'true') {
            currentEmail = email; // Store in higher scope variable
            currentVerificationCode = verificationCode; // Store in higher scope variable
            showResetPasswordModal(email, verificationCode);
        }
    }

    function showResetPasswordModal() {

        // Call the smoothScrollTo function with a responsive offset
        smoothScrollTo(document.getElementById('ofc-mint'), window.innerWidth <= 600 ? 70 : 300);
    
        // Display the reset password modal
        elements.resetPasswordModal.style.display = 'block';
        
    
        // Initialize the modal with the email and verification code if needed
        // For example:
        // elements.resetPasswordModal.querySelector('#emailField').value = email;
        // elements.resetPasswordModal.querySelector('#verificationCodeField').value = verificationCode;
    }

    // Function to hide the reset password modal
    function hideResetPasswordModal() {
        elements.resetPasswordModal.style.display = 'none';
    }

    // Function to reset user password
    function resetUserPassword() {
        const newPassword = elements.resetPasswordModal.querySelector('#newPassword').value;
        const confirmPassword = elements.resetPasswordModal.querySelector('#confirmPassword').value;

        // Validate password
        if (newPassword.length < 6) {
            alert("Password must be at least 6 characters long.");
            return;
        }
        if (!/[A-Z]/.test(newPassword)) {
            alert("Password must contain at least one uppercase letter.");
            return;
        }
        if (!/[a-z]/.test(newPassword)) {
            alert("Password must contain at least one lowercase letter.");
            return;
        }
        if (!/\d/.test(newPassword)) {
            alert("Password must contain at least one number.");
            return;
        }

        // Validate confirm password
        if (newPassword !== confirmPassword) {
            alert("Passwords do not match.");
            return;
        }

        // Prepare payload for the backend
        const payload = {
            email: currentEmail, // Use the higher scope variable
            verificationCode: currentVerificationCode, // Use the higher scope variable
            newPassword
        };

        // Send the POST request to the backend
        fetch('https://user-management.ultimatedreamsetupgiveaway.io/user/reset-password', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                alert('Password has been reset successfully.');
                elements.resetPasswordModal.style.display = 'none'; // Hide modal
            } else {
                alert('Failed to reset password: ' + data.message);
            }
        })
        .catch(error => {
            console.error('Error:', error);
            alert('An error occurred while resetting the password. Please try again.');
        });
    }


    //
    //
    // Fetch First Name
    //
    //


    // Fetches the first name of the user based on the stored session ID
    function fetchFirstName() {
        const sessionId = localStorage.getItem('sessionId');
        if (!sessionId) {
            console.error('No session ID found');
            return; // Exit the function if no session ID is found
        }

        fetch('https://user-management.ultimatedreamsetupgiveaway.io/get/fetch-first-name', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ sessionId }) // Send session ID in request body
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            // Update the DOM with the user's first name
            document.getElementById("userFirstName").textContent = data.firstName;
        })
        .catch(error => {
            console.error('Error fetching first name:', error);
        });
    }



    //
    //
    // Minting Interface    +   ETH Price Display   +    Exchange Rates API for Fiat Calculations
    //
    //


    // Making this global to also be used in the handleMintNowClickWeb3Modal funciton
    let currentQuantity = parseInt(document.getElementById("mintQuantity").textContent);

    // Function to update mint quantity and price display
    async function updateMintQuantity(change) {
        currentQuantity += change;
        currentQuantity = Math.max(1, Math.min(40, currentQuantity)); // Ensure quantity is between 1 and 40
        document.getElementById("mintQuantity").textContent = currentQuantity;
    
        // Await the completion of updating the price display
        try {
            
            await updatePriceDisplay(currentQuantity); // Update price display based on the new quantity

            await updateUIBasedOnWalletOption('M-Interface'); // Then update the UI based on the wallet option

        } catch (error) {
            console.error('Error updating mint quantity and UI:', error);
        }
    }

    // Declare priceInETH as a global variable
    let priceInETH = 0;
    let pricePerNFT = 0.04; // Define the price per NFT (assumed value)
    // Function to initialize the ETH / Fiat price display

    async function updatePriceDisplay(quantity) {

        priceInETH = quantity * pricePerNFT; // Update the global variable
        document.getElementById("priceInETH").textContent = priceInETH.toFixed(2) + " ETH";
    
        try {

            await fetchExchangeRates(priceInETH); // Wait for exchange rates to update
            
            if (quantity === 1) {
                await ethPricePlusGas(); // Wait for gas prices to update
            }
            
        } catch (error) {
            console.error('Error updating price display:', error);
        }
    
        // Any additional logic that depends on the completion of the above async operations
    }
    // Initialize the display with default values
    updatePriceDisplay(1);


    // Function to fetch exchange rates and update fiat prices
    async function fetchExchangeRates(ethAmount) {
        const cacheTimeout = 240000; // 240,000 milliseconds = 4 minutes
        const currentTime = Date.now();

        let ratesCache = localStorage.getItem('ratesCache');
        let ratesTimeoutCache = localStorage.getItem('ratesTimeoutCache');

        if (ratesCache && ratesTimeoutCache && currentTime - parseInt(ratesTimeoutCache) < cacheTimeout) {
            // Use cached rates if they are still valid
            const cachedRates = JSON.parse(ratesCache);
            const ethToCad = parseFloat(cachedRates.CAD);
            const ethToUsd = parseFloat(cachedRates.USD);
            updateFiatPrices(ethAmount, ethToCad, ethToUsd);

        } else {
            // Fetch new exchange rates from the API and update cache
            fetch('https://api.coinbase.com/v2/exchange-rates?currency=ETH')
            .then(response => response.json())
            .then(data => {
                const rates = data.data.rates;
                const ethToCad = parseFloat(rates.CAD);
                const ethToUsd = parseFloat(rates.USD);
                
                // Create an object to store only the needed rates
                const relevantRates = {
                    CAD: ethToCad.toString(),
                    USD: ethToUsd.toString()
                };

                // Cache the filtered rates
                localStorage.setItem('ratesCache', JSON.stringify(relevantRates));
                localStorage.setItem('ratesTimeoutCache', currentTime.toString());

                // Use the newly fetched rates
                updateFiatPrices(ethAmount, ethToCad, ethToUsd);
            })

            .catch(error => console.error('Error fetching exchange rates:', error));
        }
    }

    // Dedicated function to fetch exchange rates and return CAD price to pixel
    async function fetchExchangeRatesForPixel(ethAmount) {
        const cacheTimeout = 240000; // 240,000 milliseconds = 4 minutes
        const currentTime = Date.now();

        let ratesCache = localStorage.getItem('ratesCache');
        let ratesTimeoutCache = localStorage.getItem('ratesTimeoutCache');

        if (ratesCache && ratesTimeoutCache && currentTime - parseInt(ratesTimeoutCache) < cacheTimeout) {
            // Use cached rates if they are still valid
            const cachedRates = JSON.parse(ratesCache);
            const ethToCad = parseFloat(cachedRates.CAD);
            const ethToUsd = parseFloat(cachedRates.USD);

            // Return the CAD price
            return ethAmount * ethToCad;

        } else {
            // Fetch new exchange rates from the API and update cache
            try {
                const response = await fetch('https://api.coinbase.com/v2/exchange-rates?currency=ETH');
                const data = await response.json();
                const rates = data.data.rates;
                const ethToCad = parseFloat(rates.CAD);
                const ethToUsd = parseFloat(rates.USD);
                    
                // Create an object to store only the needed rates
                const relevantRates = {
                    CAD: ethToCad.toString(),
                    USD: ethToUsd.toString()
                };

                // Cache the filtered rates
                localStorage.setItem('ratesCache', JSON.stringify(relevantRates));
                localStorage.setItem('ratesTimeoutCache', currentTime.toString());

                // Return the CAD price
                return ethAmount * ethToCad;

            } catch (error) {
                console.error('Error fetching exchange rates:', error);
                return 0; // Fallback value in case of error
            }
        }
    }

    // Function to update fiat prices based on exchange rates
    function updateFiatPrices(ethAmount, ethToCad, ethToUsd) {
        const cadPrice = Math.round(ethAmount * ethToCad);
        const usdPrice = Math.round(ethAmount * ethToUsd);
        document.getElementById("priceInCAD").textContent = `CAD $${cadPrice}`;
        document.getElementById("priceInUSD").textContent = `USD $${usdPrice}`;
    }


    //
    //
    // Logic for displaying the Correct Minting Interface UI
    //
    //


    // Subscribe to login state changes.
    // Calling the function to load and use the loginState
    async function subscribeToLoginStateChanges() {
        const loginState = await loadLoginState();
        if (loginState) {
            loginState.subscribe(isLoggedIn => {
                if (isLoggedIn) {
                    updateUIBasedOnWalletOption();
                } else {
                    // Optionally, handle UI changes for when the user logs out
                }
            });
        }
    }

    async function fetchUsersWalletAttribute() {
        // Retrieve the session ID from local storage
        const sessionId = localStorage.getItem('sessionId');

        if (!sessionId) {
            console.error('Session ID is missing in local storage');
            return null; // Exit the function if there is no session ID, return null
        }

        try {
            const response = await fetch('https://user-management.ultimatedreamsetupgiveaway.io/get/fetch-wallet-option', {
                method: 'POST', 
                headers: {
                    'Content-Type': 'application/json', 
                },
                body: JSON.stringify({ sessionId: sessionId }), // Include the session ID in the request body
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json(); // Parse the JSON in the response
            return data.walletOption; // Return the fetched wallet option directly
        } catch (error) {
            console.error('There was a problem fetching the wallet option:', error);
            return null; // Return null in case of any error
        }
    }


    //
    //
    // Handle UI for displaying the Connected Wallet Address
    //
    //


    function updateEthAddressUI(connectedWalletAddress) {
        // Use the global variable connectedWalletAddress if not passed as an argument
        const ethAddress = connectedWalletAddress || '0x0000000000000000000000000000000000000000';
    
        // Update the UI element with the formatted address
        document.getElementById('userEthAddress').textContent = formatEthAddress(ethAddress);
    }

    // Helper function to format the ETH address
    function formatEthAddress(address) {
        // Extract the first 4 and last 4 characters
        const firstFour = address.substring(0, 6); // Including '0x' prefix
        const lastFour = address.substring(address.length - 4);
        return `${firstFour} ..... ${lastFour}`;
    }


    //
    //
    // Update ETH address in Cognito (If connected wallet is new && not null)
    //
    //


    // Async function to fetch and potentially update the ETH address in Cognito
    async function fetchAndUpdateCognitoEthAddress() {
        const sessionId = localStorage.getItem('sessionId');
        
        // Check if the session ID exists
        if (!sessionId) {
            console.error('No session ID found');
            throw new Error('No session ID found'); // Throw an error if no session ID
        }
        
        try {
            const response = await fetch('https://user-management.ultimatedreamsetupgiveaway.io/get/fetch-eth-address', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ sessionId })
            });

            // Await the response to be converted to JSON
            const data = await response.json();
            const cognitoEthAddress = data.ethAddress;
            
            // Validate the addresses before updating to prevent unnecessary updates
            if (!cognitoEthAddress || cognitoEthAddress === '0x0000000000000000000000000000000000000000' || !connectedWalletAddress || connectedWalletAddress === '0x0000000000000000000000000000000000000000') {
                console.log('Either cognitoEthAddress or connectedWalletAddress is null/invalid. Skipping update.');
                return; // Exit the function if validation fails
            }
            
            // Check if the wallet addresses differ
            if (cognitoEthAddress !== connectedWalletAddress) {
                // Make an update call to Cognito if the addresses are different
                const updateResponse = await fetch('https://user-management.ultimatedreamsetupgiveaway.io/user/update-eth-address', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        sessionId: sessionId,
                        walletAddress: connectedWalletAddress
                    })
                });

                if (!updateResponse.ok) {
                    throw new Error('Failed to update wallet address in Cognito');
                }
                
                const updateData = await updateResponse.json();
                console.log('Wallet address updated in Cognito:', updateData);
            } else {
                // console.log('Cognito address matches the connected wallet address. No update needed.');
            }

        } catch (error) {
            console.error('Error in fetching/updating ETH address:', error);
            throw error; // Propagate the error
        }
    }


    //
    //
    // Fetch Users Wallet Balance.   +   Used to show insufficient ETH UI.
    //
    //


    async function fetchUsersBalance(connectedWalletAddress) {
        try {
            const userAddress = connectedWalletAddress;

            // Then, fetch the user's balance using the address
            const balanceResponse = await fetch('https://user-management.ultimatedreamsetupgiveaway.io/get/fetch-user-balance', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ address: userAddress }),
            });

            if (!balanceResponse.ok) {
                throw new Error('Network response was not ok');
            }

            const balanceData = await balanceResponse.json();
            return balanceData.balance; // Return the fetched balance directly
        } catch (error) {
            console.error('Error:', error);
            return null; // Return null in case of an error
        }
    }


    //
    //
    // Logic for Calculating GAS PRICES   -   for insufficient ETH UI
    //
    //


    let totalCostInETH = null;

    async function ethPricePlusGas() {
        try {
            const cacheTimeout = 120000; // 120,000 milliseconds = 2 minutes
            const currentTime = Date.now();

            let gasValueCache = localStorage.getItem('gasValueCache');
            let gasTimeoutCache = localStorage.getItem('gasTimeoutCache');

            let rapidGasPriceWei;

            if (gasValueCache && gasTimeoutCache && currentTime - parseInt(gasTimeoutCache) < cacheTimeout) {
                // Use cached gas value if it's still valid
                rapidGasPriceWei = parseFloat(gasValueCache);

            } else {
                // Fetch new gas price from the API and update cache
                const response = await fetch('https://beaconcha.in/api/v1/execution/gasnow');
                const data = await response.json();
                const rapidGasPriceWei = data.data.rapid; // Rapid gas price in wei

                // Update cache
                localStorage.setItem('gasValueCache', rapidGasPriceWei.toString());
                localStorage.setItem('gasTimeoutCache', currentTime.toString());
            }
            
            // Base gas limit for minting one NFT, plus additional gas for each extra NFT
            // Starting with a base limit of 150,000 for one NFT and adding 100,000 for each additional NFT

            // ********* Test a transaction on Mainnet to see what the "Gas Used" is when minting ERC-721. ************
            // Mainnet used: 105,109 Gas Limit
            const baseGasLimit = 110000;
            const additionalGasPerNFT = 81000;
            const totalGasLimit = baseGasLimit + (currentQuantity - 1) * additionalGasPerNFT;

            // Convert the "Rapid" gas price from wei to ETH for estimation
            const rapidGasCostInETH = (rapidGasPriceWei * totalGasLimit) / 1e18;
    
            // Total cost estimation in ETH (priceInETH includes the quantity)
            totalCostInETH = priceInETH + rapidGasCostInETH;
    
            // Fetch exchange rates and update fiat prices
            fetchExchangeRates2(totalCostInETH);
            // $3,900 ETH
            // GAS: 0.008
            // APPROX: $34 USD
            
        } catch (error) {
            console.error('Error fetching gas prices:', error);
        }
    }
    function fetchExchangeRates2(ethAmount) {
        const cacheTimeout = 240000; // 240,000 milliseconds = 4 minutes
        const currentTime = Date.now();

        let ratesCache = localStorage.getItem('ratesCache');
        let ratesTimeoutCache = localStorage.getItem('ratesTimeoutCache');

        if (ratesCache && ratesTimeoutCache && currentTime - parseInt(ratesTimeoutCache) < cacheTimeout) {
            // Use cached rates if they are still valid
            const cachedRates = JSON.parse(ratesCache);
            const ethToCad = parseFloat(cachedRates.CAD);
            const ethToUsd = parseFloat(cachedRates.USD);
            updateFiatPrices2(ethAmount, ethToCad, ethToUsd);

        } else {
            // Fetch new exchange rates from the API and update cache
            fetch('https://api.coinbase.com/v2/exchange-rates?currency=ETH')
            .then(response => response.json())
            .then(data => {
                const rates = data.data.rates;
                const ethToCad = parseFloat(rates.CAD);
                const ethToUsd = parseFloat(rates.USD);
                
                // Create an object to store only the needed rates
                const relevantRates = {
                    CAD: ethToCad.toString(),
                    USD: ethToUsd.toString()
                };

                // Cache the filtered rates
                localStorage.setItem('ratesCache', JSON.stringify(relevantRates));
                localStorage.setItem('ratesTimeoutCache', currentTime.toString());

                // Use the newly fetched rates
                updateFiatPrices2(ethAmount, ethToCad, ethToUsd);
            })

            .catch(error => console.error('Error fetching exchange rates:', error));
        }
    }
    function updateFiatPrices2(ethAmount, ethToCad, ethToUsd) {
        const cadPrice = Math.ceil(ethAmount * ethToCad) + 15; // Adding $15 CAD to the calculated amount
        const usdPrice = Math.ceil(ethAmount * ethToUsd) + 11; // Adding $11 USD to the calculated amount
        document.getElementById("ETHPlusGas").innerHTML = `CAD $${cadPrice}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;USD $${usdPrice}`;
    }


    //
    //
    // Handle MAIN UI for Minting Interface
    //
    //


    let connectedWalletAddress = null;
    let connectedUsersETHBalance = 5;

    let walletOption = null;

    // Function to handle the UI of the Minting Interface based on the wallet option
    // Check login state and call other functions accordingly
    async function updateUIBasedOnWalletOption(fetchOption) {

        // Call initializeWeb3Modal to setup everything
        // !web3Modal is critical to avoid loop
        if (!web3Modal) {
            await initializeWeb3Modal();
        }

        // Dynamically import the WalletConnectionState module
        const walletStateModule = await walletConnectionState();
        if (!walletStateModule) {
            console.error("WalletConnectionState module is not available.");
            return;
        }
    
        if (fetchOption !== 'M-Interface') {
            walletOption = await fetchUsersWalletAttribute(); // Fetch the user's wallet option
        }

        await ethPricePlusGas(); // Update the global variable totalCostInETH
    
        // Used to prompt users to install 
        if (walletOption === "noWallet") {

            /*
            document.getElementById('disconnectWalletButton').style.display = 'none';
            */
            elements.connectWalletDiv.style.display = 'flex';
            // Promt user to connect OR ***create/download*** a wallet
            elements.downloadWalletDiv.style.display = 'block';

            // Hide unnecessary components
            elements.coreMintingFunctionality.style.display = 'none';
            elements.mintNowButtonWeb3Modal.style.display = 'none';
            elements.mintingEthAddressDiv.style.display = 'none';

            // Hide the loading animation
            elements.loadingAnimation.style.display = 'none';

        } else if (walletOption === "thirdParty") {

            // Use the imported module's state to check connection
            if (!walletStateModule.isConnected) {
                /*
                document.getElementById('disconnectWalletButton').style.display = 'none';
                */
                elements.connectWalletDiv.style.display = 'flex';
                elements.coreMintingFunctionality.style.display = 'none';
                // Web3Modal
                elements.mintNowButtonWeb3Modal.style.display = 'none';

                elements.mintingEthAddressDiv.style.display = 'none';
                elements.downloadWalletDiv.style.display = 'none';

                elements.accountCTADiv.style.display = 'none';
                elements.wrapperDisplayDiv.style.display = 'none';

                // Hide the loading animation
                elements.loadingAnimation.style.display = 'none';
            } else {
                elements.connectWalletDiv.style.display = 'none';
                elements.downloadWalletDiv.style.display = 'none';

                elements.coreMintingFunctionality.style.display = 'block';

                elements.mintingEthAddressDiv.style.display = 'block';

                elements.mintNowButtonWeb3Modal.style.display = 'block';

                // Hide the loading animation
                elements.loadingAnimation.style.display = 'none';

                // Check if the user's ETH balance is less than the total cost
                // REMOVED THE GAS TO HAVE USER ATLEAST ATTEMPT MINTING, WITHOUT OVER ESTIMATING (priceInETH)
                if (connectedUsersETHBalance < priceInETH) {
                    elements.insufficientETHBalanceDiv.style.display = 'block';
                    // Call the smoothScrollTo function with a responsive offset
                    smoothScrollTo(document.getElementById('mintQuantity'), window.innerWidth <= 600 ? 80 : 300);
                } else {
                    elements.insufficientETHBalanceDiv.style.display = 'none';
                }
            }
        }
        // Logic for both can be placed here
    }
    

    //
    //
    // Handle LINK CLICKS for the 4 step insufficient ETH UI
    //
    //


    // Copy the ETH Address
    async function copyEthAddress() {
        try {
            await navigator.clipboard.writeText(connectedWalletAddress);

            // Get the button element
            const button = document.getElementById('copyEthAddress');
            
            // Change the button text to "Address Copied"
            button.textContent = 'Address Copied';

            // Change the button background color to indicate success
            button.style.backgroundColor = 'green';

            // Dynamically add a style element for hover effects
            const styleElement = document.createElement('style');
            styleElement.id = 'copyButtonHoverStyle'; // Assign an ID for future reference
            document.head.appendChild(styleElement);
            styleElement.textContent = `
                #copyEthAddress:hover {
                    background-color: #006400 !important; /* Slightly darker shade of green */
                }
            `;

            // Reset the button text after a few seconds
            setTimeout(() => {
                button.textContent = 'Copy Address'; // Reset text
                button.style.backgroundColor = '#7A00CC'; // Reset background color to original

                // Remove the dynamic hover styles
                const hoverStyleElement = document.getElementById('copyButtonHoverStyle');
                if (hoverStyleElement) {
                    hoverStyleElement.remove();
                }

            }, 3000); // Resets the text after 3 seconds

        } catch (error) {
            console.error('Error copying ETH address to clipboard:', error);
        }
    }


    // Handle the Navigation for Mercuryo (Link Button)
    async function handleMercuryoLinkClick(event) {

        // Prevent the default link behavior (So the existing tab doesn't also navigate)
        event.preventDefault();

        // Open a new tab with the specified URL
        window.open('https://exchange.mercuryo.io/', '_blank');
    }

    // Handle the scroll up button click with dynamic adjustment
    async function handleScrollUpClick() {

        // Call the smoothScrollTo function with a responsive offset
        smoothScrollTo(document.getElementById('ofc-mint'), window.innerWidth <= 600 ? 70 : 300);

        // Used to update the users balance to see if they still have enough to mint.
        // Will remove the Insufficient ETH Balance section if they now do have enough...
        connectedUsersETHBalance = await fetchUsersBalance(connectedWalletAddress);
        updateUIBasedOnWalletOption('M-Interface');
    }


    //
    //
    // Logic for Web3Modal + Wallet Connection State Management
    //
    //


    // Making the modal globally available
    let web3Modal;
    let signer;

    async function initializeWeb3Modal() {
        
        try {
            const response = await fetch('https://user-management.ultimatedreamsetupgiveaway.io/get/fetch-project-id', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const data = await response.json();
            const projectId = data.projectId;
    
            // Setting up the web3Modal
            const mainnet = {
                chainId: 1,
                name: 'Ethereum',
                currency: 'ETH',
                explorerUrl: 'https://etherscan.io',
                rpcUrl: 'https://mainnet.infura.io/v3/c17c02ea265a4844b93e1ed41b8d5413',
            };
            // USE TO FIND BEST RPC: https://chainlist.org/chain/1
            // wss may not be the best connection type as I get an error on mobile not using HTTP
            /*
            const sepolia = {
                chainId: 11155111,
                name: 'Sepolia',
                currency: 'ETH',
                explorerUrl: 'https://sepolia.etherscan.io',
                rpcUrl: 'https://ethereum-sepolia.blockpi.network/v1/rpc/public',
            };
            */
            const metadata = {
                name: 'Ultimate Dream Setup Giveaway',
                description: 'Empowering creators with blockchain based technologies',
                url: 'https://project-udsg.com',
                icons: ['https://project-udsg.com/wp-content/uploads/2023/03/cropped-Enhanced-Profile-min.png'],
            };
            // WalletConnectProvider is included via @web3modal/ethers
            // DEFAULT IS MAINNET? (eip155) (IF NOT SPECIFIED): 
            // https://rpc.walletconnect.com/v1/?chainId=eip155:0&projectId=d58ddfd56a64401399f36ab68afddbfb

            web3Modal = createWeb3Modal({
                ethersConfig: defaultConfig({ metadata }),
                chains: [mainnet],
                cacheProvider: true,
                projectId,
                themeMode: 'dark',
                autoConnect: true,
                enableAnalytics: false,
                themeVariables: {
                    '--w3m-z-index': '998'
                }
            });
    
            // Setup button click handler here, after web3Modal is initialized
            document.getElementById('connectWalletButton').addEventListener('click', async () => {
                // Opens the Web3Modal to let the user select their wallet
                await web3Modal.open();
            });

            /*
            // Setup button click handler here, after web3Modal is initialized
            document.getElementById('disconnectWalletButton').addEventListener('click', async () => {
                // Disconnects the connection
                web3Modal.disconnect();
            });
            */

            // Subscribing to provider changes (account change, chain change, etc.)
            web3Modal.subscribeProvider(async (changeEvent) => {
                const { provider, providerType, address, error, chainId, isConnected } = changeEvent;

                if (walletOption === "noWallet") {
                    try {
                        const sessionId = localStorage.getItem('sessionId');
                        if (!sessionId) {
                            console.error('Session ID not found - Attempted to add eth address to account');
                        }

                        const response = await fetch('https://user-management.ultimatedreamsetupgiveaway.io/user/update-eth-address', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                sessionId: sessionId,
                                walletAddress: address
                            })
                        });
                
                        if (!response.ok) {
                            throw new Error('Network response was not ok');
                        }
                
                        const data = await response.json();
                        console.log('Wallet address updated in Database:', data);
                
                    } catch (error) {
                        console.error('Error updating users wallet address (Already Updated?):', error);
                    }
                }
            
                // Handle errors
                if (error) {
                    console.error("Error with the provider:", error);
                    return;
                }
            
                // Dynamically import the WalletConnectionState module
                const walletStateModule = await walletConnectionState();
                if (!walletStateModule) {
                    console.error("WalletConnectionState module is not available.");
                    return;
                }
            
                // Update connection state based on the provider event
                if (isConnected) {

                    const walletProvider = await web3Modal.getWalletProvider();

                    // Initialize ethers.js provider and signer
                    const ethersProvider = new BrowserProvider(walletProvider);
                    signer = ethersProvider.getSigner();

                    // Meta Pixel tracking wallet connection as an "AddToCart"
                    fbq('track', 'AddToCart');

                    // TikTok Pixel tracking wallet connections (Add to Cart)
                    // Function to fetch user info from the backend
                    async function fetchUserInfo(sessionId) {
                        try {
                            const response = await fetch('https://user-management.ultimatedreamsetupgiveaway.io/get/fetch-user-info', {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify({ sessionId: sessionId })
                            });
                            
                            if (!response.ok) {
                                throw new Error('Failed to fetch user info');
                            }
                            
                            const userInfo = await response.json();
                            return userInfo;
                        } catch (error) {
                            console.error('Error fetching user info:', error);
                            return null; // Handle error appropriately
                        }
                    }

                    // Main function to execute Pixel tracking
                    async function trackAddToCart() {
                        // Retrieve sessionId from localStorage
                        const sessionId = localStorage.getItem('sessionId');
                        
                        if (!sessionId) {
                            console.error('Session ID not found in local storage');
                            return; // Handle missing sessionId
                        }

                        // Fetch user info from the backend
                        const userInfo = await fetchUserInfo(sessionId);

                        if (!userInfo || !userInfo.email) {
                            console.error('User info could not be retrieved');
                            return; // Handle missing user info
                        }

                        // Create a new object for Pixel data
                        let tikTokUserData = {
                            email: userInfo.email, // Extract email from userInfo
                            external_id: userInfo.email // Use email as the external_id by default
                        };

                        // Check if the phone number is present in userInfo, then add it to tikTokUserData
                        if (userInfo.phone_number && userInfo.phone_number !== '+15555555555') {
                            tikTokUserData.phone_number = userInfo.phone_number; // Add phone number if available
                        }

                        const priceInETHATC = 0.04;

                        let cadFiatPrice;
                        try {
                            // Fetch CAD equivalent for 1 NFT in ETH
                            cadFiatPrice = await fetchExchangeRatesForPixel(priceInETHATC);
                        } catch (error) {
                            console.error('Error fetching exchange rates:', error);
                            return; // Handle error if fetching rates fails
                        }

                        // Now call ttq.identify with the tikTokUserData object
                        ttq.identify(tikTokUserData);

                        // Fire the event (Add to Cart - Wallet Connection with baseline value)
                        ttq.track('AddToCart', {
                            "contents": [
                                {
                                    "content_id": "UDSG #01",
                                    "content_type": "product",
                                    "content_name": "UDSG #01"
                                }
                            ],
                            "value": cadFiatPrice.toFixed(2), // Example baseline value for 1 NFT
                            "currency": "CAD"
                        });
                    }

                    // Call the main function
                    trackAddToCart();

                    /*
                    document.getElementById('disconnectWalletButton').style.display = 'block';
                    */

                    // Call the smoothScrollTo function with a responsive offset
                    smoothScrollTo(document.getElementById('ofc-mint'), window.innerWidth <= 600 ? 70 : 300);

                    // If connected, use the address and chainId from the event
                    walletStateModule.setConnectionState(true, address);

                    // THIS WOULD NEED TO BE CALLED ON APPLICAITON START!:
                    // Used to have persistence in the connected state (Maintains connection even if cache is cleared)
                    // window.isConnectedGlobal = isConnected;

                    connectedWalletAddress = address;
                    connectedUsersETHBalance = await fetchUsersBalance(address);
                    updateEthAddressUI(address);
                    fetchAndUpdateCognitoEthAddress();

                    updateUIBasedOnWalletOption();
                } else {

                    // If not connected, set the state accordingly
                    walletStateModule.setConnectionState(false);

                    // Used to have persistence in the connected state (Maintains connection even if cache is cleared)
                    // window.isConnectedGlobal = !isConnected;

                    connectedWalletAddress = null;
                    updateEthAddressUI(connectedWalletAddress);

                    updateUIBasedOnWalletOption();
                }
            });

        } catch (error) {
            console.error('Failed to fetch projectId or initialize Web3Modal:', error);
        }
    }


    //
    //
    // Download Wallet UI Logic     +      Handle Link Clicks
    //
    //


    // URLs for MetaMask on respective app stores
    const trustWalletGooglePlayURL = 'https://play.google.com/store/apps/details?id=com.wallet.crypto.trustapp&hl=en&gl=US';
    const trustWalletAppStoreURL = 'https://apps.apple.com/us/app/trust-crypto-bitcoin-wallet/id1288339409';
    const chromeWebStoreURL = 'https://chromewebstore.google.com/detail/trust-wallet/egjidjbpglichdcondbcbdnbeeppgdph';

    // Get the elements by their class names
    const googlePlayStoreElement = document.querySelector('.google-play-store');
    const appStoreElement = document.querySelector('.apple-app-store');
    const chromeWebStoreElement = document.querySelector('.chrome-web-store');

    // Add click event listener for Google Play Store image
    googlePlayStoreElement.addEventListener('click', function() {
        window.open(trustWalletGooglePlayURL, '_blank');
    });

    // Add click event listener for Apple App Store image
    appStoreElement.addEventListener('click', function() {
        window.open(trustWalletAppStoreURL, '_blank');
    });

    // Add click event listener for Chrome Web Store image
    chromeWebStoreElement.addEventListener('click', function() {
        window.open(chromeWebStoreURL, '_blank');
    });


    // URL where the user should be redirected to view their account
    const accountUrl = 'https://project-udsg.com/account/';

    // Make "View My Account" clickable
    document.getElementById('accountCTA').addEventListener('click', function() {
        window.location.href = accountUrl;
    });
    document.getElementById('accountCTAlink').addEventListener('click', function() {
        window.location.href = accountUrl;
    });
    document.querySelector('.nft-display-wrapper').addEventListener('click', function() {
        window.location.href = accountUrl;
    });


    //
    //
    // Mint Now Click    +     Minting Interface    +    Handle Mint / Blockchain Interations
    //
    //


    // Event handler for the 'Mint Now' button click
    async function handleMintNowClickWeb3Modal() {

        // Used to update the users balance to see if they still have enough to mint.
        // Will remove the Insufficient ETH Balance section if they now do have enough...
        connectedUsersETHBalance = await fetchUsersBalance(connectedWalletAddress);
        updateUIBasedOnWalletOption('M-Interface');

        window.onbeforeunload = function() {
            return "Please wait for the minting process to finish. Before leaving or re-freshing the page.";
        };
        const pleaseWaitText = document.getElementById('mintingStatusParagraph');
        pleaseWaitText.style.display = 'block';

        if (!web3Modal) {
            await initializeWeb3Modal();
        }

        // Used for UI elements
        const button = this; // Capture the button context
        let dotCount = 0;
        const buttonText = "Minting";
        const maxDots = 3;
        let intervalId;

        const quantity = currentQuantity;
        console.log("Minting process initiated for " + quantity + " NFTs");

        // Used for UI elements
        this.style.background = "linear-gradient(90deg, #9C00FF 0%, #7000b6 35%, #470074 100%)";
        // Function to update button text with dynamic dots
        const updateText = () => {
            button.innerHTML = `${buttonText}${'.'.repeat(dotCount)}`;
            dotCount = (dotCount + 1) % (maxDots + 1);
        };
        // Start the dynamic text effect
        intervalId = setInterval(updateText, 500);

        // Function to ensure signer is initialized
        async function ensureSigner() {
            if (!signer) {
                console.log('Signer was not initialized. Re-initializing.');
                const walletProvider = await web3Modal.getWalletProvider(); // Get the connected wallet provider
                const ethersProvider = new BrowserProvider(walletProvider);
                signer = ethersProvider.getSigner(); // Set the global signer variable
            }
            return signer; // Return the initialized signer
        }

        try {

            // Ensure signer is initialized or re-initialized if necessary
            const activeSigner = await ensureSigner();

            // Contract address and ABI
            const contractAddress = "0xF620DC2C1C6769F28D7f7b3e8eacd98d0F932300";
            
            // Fetching the ABI fromt the backend
            const contractABI = await fetchContractABI();
    
            // Interacting with the contract
            const nftContract = new Contract(contractAddress, contractABI, activeSigner);
    
            // Sending payment based on quantity
            const mintValue = parseUnits((pricePerNFT * quantity).toString(), 'ether');
            const tx = await nftContract.mint(await activeSigner.getAddress(), quantity, { value: mintValue });
            const receipt = await tx.wait();

            // Used for accounting:
            const salesTransactionHash = receipt.hash;
            console.log('Transaction Hash: ', salesTransactionHash);
            
            // Setting up a filter for the Transfer event
            const filter = nftContract.filters.Transfer(null, activeSigner);
            // Querying the filter for the past block
            const events = await nftContract.queryFilter(filter, receipt.blockNumber, receipt.blockNumber);

            console.log("Minting successful for " + quantity + " NFTs");



            if (events.length > 0) {

                async function processTokenIds(events) {
                    let firstTokenId = parseInt(events[0].args.tokenId.toString());
                    let lastTokenId = firstTokenId;  // Start with the assumption of a single NFT minted
                
                    for (const event of events) {
                        const currentTokenId = parseInt(event.args.tokenId.toString());
                        if (currentTokenId < firstTokenId) {
                            firstTokenId = currentTokenId; // Update if a smaller tokenId is found
                        }
                        if (currentTokenId > lastTokenId) {
                            lastTokenId = currentTokenId; // Update if a larger tokenId is found
                        }
                    }
                    return { firstTokenId, lastTokenId };
                }

                const { firstTokenId, lastTokenId } = await processTokenIds(events);

                const cadFiatPrice = await fetchExchangeRatesForPixel(priceInETH);

                // Meta Pixel tracking a purchase
                fbq('track', 'Purchase', {
                    value: cadFiatPrice.toFixed(2),  // Use the dynamic CAD price (rounded to 2 decimal places)
                    currency: 'CAD' // Replace with the appropriate currency
                });

                // TikTok Pixel tracking purchases (Complete Payment)
                // Function to fetch user info from the backend
                async function fetchUserInfo(sessionId) {
                    try {
                        const response = await fetch('https://user-management.ultimatedreamsetupgiveaway.io/get/fetch-user-info', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({ sessionId: sessionId })
                        });
                        
                        if (!response.ok) {
                            throw new Error('Failed to fetch user info');
                        }
                        
                        const userInfo = await response.json();
                        return userInfo;
                    } catch (error) {
                        console.error('Error fetching user info:', error);
                        return null; // Handle error appropriately
                    }
                }

                // Main function to execute Pixel tracking for purchase
                async function trackCompletePayment() {
                    // Retrieve sessionId from localStorage
                    const sessionId = localStorage.getItem('sessionId');
                    
                    if (!sessionId) {
                        console.error('Session ID not found in local storage');
                        return; // Handle missing sessionId
                    }

                    // Fetch user info from the backend
                    const userInfo = await fetchUserInfo(sessionId);

                    if (!userInfo || !userInfo.email) {
                        console.error('User info could not be retrieved');
                        return; // Handle missing user info
                    }

                    // Create a new object for Pixel data
                    let tikTokUserData = {
                        email: userInfo.email, // Extract email from userInfo
                        external_id: userInfo.email // Use email as the external_id by default
                    };

                    // Check if the phone number is present in userInfo, then add it to tikTokUserData
                    if (userInfo.phone_number && userInfo.phone_number !== '+15555555555') {
                        tikTokUserData.phone_number = userInfo.phone_number; // Add phone number if available
                    }

                    let cadFiatPrice;
                    try {
                        // Fetch CAD equivalent for 1 NFT in ETH
                        cadFiatPrice = await fetchExchangeRatesForPixel(priceInETH);
                    } catch (error) {
                        console.error('Error fetching exchange rates:', error);
                        return; // Handle error if fetching rates fails
                    }

                    // Now call ttq.identify with the tikTokUserData object
                    ttq.identify(tikTokUserData);

                    // Fire the event (Complete Payment - Purchase with actual value)
                    ttq.track('CompletePayment', {
                        "contents": [
                            {
                                "content_id": "UDSG #01",
                                "content_type": "product",
                                "content_name": "UDSG #01"
                            }
                        ],
                        "value": cadFiatPrice.toFixed(2), // Final purchase price in CAD
                        "currency": "CAD"
                    });
                }

                // Call the main function
                trackCompletePayment();

                let campaignDuringMint = []; // Initialize an array to hold multiple campaigns
                let campaignTypeDuringMint = []; // Initialize an array to hold multiple campaign types

                // Tracking all possible campaigns
                const adTrackingCampaignAffiliate2 = localStorage.getItem('adTrackingCampaignAffiliate');
                const adTrackingCampaign2 = localStorage.getItem('adTrackingCampaign');
                const organicCampaign = localStorage.getItem('organicCampaign');

                // Add campaign types to the array if present
                if (adTrackingCampaignAffiliate2) {
                    campaignDuringMint.push(adTrackingCampaignAffiliate2);
                    campaignTypeDuringMint.push('affiliate');
                }
                if (adTrackingCampaign2) {
                    campaignDuringMint.push(adTrackingCampaign2);
                    campaignTypeDuringMint.push('ad');
                }
                if (organicCampaign && organicCampaign.trim() !== "") {
                    campaignDuringMint.push(organicCampaign);
                    campaignTypeDuringMint.push('organic');
                }

                // If no campaigns were found, default to 'organic'
                if (campaignDuringMint.length === 0) {
                    campaignDuringMint.push('organic');
                    campaignTypeDuringMint.push('organic');
                }

                //
                // Check for valid affiliate code on users account:
                //
                //
                // Generate a sales receipt in QuickBooks && account for affiliate expense *if eligible*
                //
                affiliateCommissionAndSalesReceipt(quantity, pricePerNFT, firstTokenId, lastTokenId, salesTransactionHash, campaignDuringMint, campaignTypeDuringMint);
                
                console.log('Request Sent to Backend - Commission / Receipt / Accounting');



                // Display information for the minted NFT(s)
                // If multiple NFTs were minted (firstTokenId != lastTokenId), pass both; otherwise, pass null as the lastTokenId
                displayFirstMintedNFT(firstTokenId.toString(), firstTokenId !== lastTokenId ? lastTokenId.toString() : null);

                // Call updateSupplyBar to account for the freshly minted NFT's
                loadAndFetchNftSupply();
                clearInterval(intervalId); // Stop the dynamic text effect
                // Successful mint operation indication
                this.innerHTML = "Mint Success";
                this.style.background = "linear-gradient(90deg, #00e400 0%, #00e400 35%, #007c00 100%)";
                // Revert to the original state after an additional 8 seconds
                setTimeout(() => {
                    this.innerHTML = "Mint Now";
                    this.style.removeProperty('background');
                }, 8000); // 8000 milliseconds



            } else {
                console.error("No NFT minted event found or unable to retrieve tokenId.");
            }


        } catch (error) {
            console.error("Failed to mint NFTs:", error);

            clearInterval(intervalId); // Stop the dynamic text effect
            this.innerHTML = "Mint Cancelled.";
            this.style.background = "linear-gradient(90deg, #9C00FF 0%, #7000b6 35%, #470074 100%)";
            // Revert to the original state after 3 seconds
            setTimeout(() => {
                this.innerHTML = "Mint Now";
                button.style.removeProperty('background');
            }, 3000); // 3000 milliseconds = 3 seconds

            // Used to update the users balance to see if they still have enough to mint after minting.
            // Will show the Insufficient ETH Balance section if they do not...
            connectedUsersETHBalance = await fetchUsersBalance(connectedWalletAddress);
            updateUIBasedOnWalletOption('M-Interface');

        } finally {
            window.onbeforeunload = null; // Disable the warning after the operation

            const pleaseWaitText = document.getElementById('mintingStatusParagraph');
            pleaseWaitText.style.display = 'none';
        }

    }
    
    // Fetch the contract ABI from the backend
    async function fetchContractABI() {
        try {
            // Fetch the contract ABI from your backend
            const response = await fetch('https://minting-app.ultimatedreamsetupgiveaway.io/fetch-contract-abi', {
                method: 'POST',
            });
            const data = await response.json();
            return data.abi;

        } catch (error) {
            console.error("Failed to fetch contract ABI:", error);
            throw error;
        }
    }
    

    //
    //
    // Logic to display the NFT & Retrieve / Display MetaData after mint    +   Account CTA
    //
    //


    // Display NFT after successful Mint
    async function displayFirstMintedNFT(firstTokenId, lastTokenId = null) {
        try {

            // Wrapper for handling transition / display of NFT
            elements.accountCTADiv.style.display = 'flex';
            elements.wrapperDisplayDiv.style.display = 'flex';
            // Transitions out if already minted:
            elements.wrapperDisplayDiv.classList.remove('ready');

            // Call the smoothScrollTo function with a responsive offset
            smoothScrollTo(document.getElementById('priceInETH'), window.innerWidth <= 600 ? 70 : 500);
            elements.loadingSpinnerNFT.style.display = 'flex';

            
            // The tokenURI needs to be appended to base URI
            const baseURI = "https://ownership-metadata.ultimatedreamsetupgiveaway.io/api/token/";
            const tokenURI = baseURI + firstTokenId; // Construct the full URI
            
            const response = await fetch(tokenURI);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const metadata = await response.json();

            const videoSrc = metadata.animation_url;

            const videoElement = document.getElementById('displayNFT');
            videoElement.src = videoSrc;
            videoElement.setAttribute('loop', true);
            videoElement.removeAttribute('controls'); // Remove

            // Listen for the video being ready to play
            videoElement.addEventListener('loadeddata', () => {

                elements.loadingSpinnerNFT.style.display = 'none';

                // Make the container visible and allow it to expand
                elements.wrapperDisplayDiv.classList.add('ready');

                // Play the video after ensuring it's loaded
                videoElement.play();
            });

            // Update the infoElement text based on whether lastTokenId is provided and different
            if (lastTokenId && firstTokenId !== lastTokenId) {
                elements.infoElement.textContent = `Project_UDSG #${firstTokenId} - #${lastTokenId}`;
            } else {
                elements.infoElement.textContent = `Project_UDSG #${firstTokenId}`;
            }

        } catch (error) {
            console.error("Failed to display NFT:", error);
        }
    }
        

    //
    //
    // Logic to handle affiliate commission *if eligible*
    // Generate Sales Receipt
    // Handle accounting
    //
    //


    async function affiliateCommissionAndSalesReceipt(quantity, pricePerNFT, firstTokenId, lastTokenId, salesTransactionHash, campaignDuringMint, campaignTypeDuringMint) {

        // Retrieve the sessionId from local storage
        const sessionId = localStorage.getItem('sessionId');
    
        // Check if sessionId exists
        if (!sessionId) {
            console.error('No session ID found. User must be logged in.');
            return; // Exit the function if no sessionId found
        }

        // Define the request body
        const requestBody = {
            sessionId: sessionId,
            quantity: quantity,
            ethPricePerNFT: pricePerNFT,
            firstTokenId: firstTokenId,
            lastTokenId: lastTokenId,
            salesTransactionHash: salesTransactionHash,
            campaignDuringMint: campaignDuringMint,
            campaignTypeDuringMint: campaignTypeDuringMint
        };

        try {
            const response = await fetch('https://user-management.ultimatedreamsetupgiveaway.io/affiliate/handle-affiliate-commission-and-sales-receipt', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody)
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
    
            const data = await response.json(); // Parse the JSON in the response
    
            if (data.success) {
                console.log("Operation successful!", data);
            
            } else {
                console.error("Operation failed:", data);

            }
    
        } catch (error) {
            console.error('There was a problem handling the affiliate commission or generating the sales receipt:', error);

        }
    }
    

    //
    //
    // Logic to handle the display of the bi-weekly GPU giveaway modal
    //
    //


    // Get the modal
    var infoModal = document.getElementById("infoModal");

    // Get the button that opens the modal
    var infoBtn = document.getElementById("info-button");

    // Get the button that closes the modal
    var infoCloseButton = document.getElementById("close-modal-button");

    // When the user clicks the button, open the modal 
    infoBtn.onclick = function() {
        infoModal.style.display = "block";
    }

    // When the user clicks the close button, close the modal
    infoCloseButton.onclick = function() {
        infoModal.style.display = "none";
    }

    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function(event) {
        if (event.target == infoModal) {
            infoModal.style.display = "none";
        }
    }

    // GoogleAuth
    // Get the modal
    var infoModalCA = document.getElementById("infoModalCompleteAccount");
    // Get the button that opens the modal
    var infoBtnCA = document.getElementById("infoModalButton");
    // Get the button that closes the modal
    var infoCloseButtonCA = document.getElementById("close-modal-button-complete-account");
    // When the user clicks the button, open the modal 
    infoBtnCA.onclick = function() {
        infoModalCA.style.display = "block";
    }
    // When the user clicks the close button, close the modal
    infoCloseButtonCA.onclick = function() {
        infoModalCA.style.display = "none";
    }
    


});